<template>
  <div class="about">
    <div class="row margin-0 justify-content-center">
      <div class="col-md-11 bg-white">
        <div class="row margin-0 justify-content-center">
          <div class="col-md-12">
            <div class="about-block text-center">
              <img src="/logo.png" style="max-width: 20vh;margin-bottom:2vh;" alt="">
              <h4>What is EBechana?</h4>
              <p class="txt-grey margin-bottom-20">EBechana is a online free classified platform to connect a customer to customer based on their requirements. It also helps businesses to create their own store for free. Customer or a Business user can create unlimited ads. Their will be no fee for ads.</p>
              <!-- <h4>How it works?</h4> -->
              <!-- <h4>Who am I?</h4>
              <div class="row margin-0">
                <div class="col-12 pad-0">
                  <img src="/founder.jpeg" alt=""><br>
                  <h5 class="pad-top-10"><b>Venkata Sai Katepalli - Founder</b></h5>
                  <p class="txt-grey">Every person having their daily requirements electronics, Home needs, books etc. I faced little difficult to find perfect goods to match my requirements. So, I started exploring the outside. Finally, I got an idea to build a solution for future markets to earn some revenu with less expenditure.</p>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="row margin-0 margin-bottom-30 justify-content-center">
          <div class="col-md-12 col-lg-10 col-xl-10 col-12 empd">
            <h4 class="margin-bottom-20 text-center">How it works for you?</h4>
            <div class="row margin-20 justify-content-between">
              <div class="col-md-3 pad-0 text-center">
                <img src="https://img.freepik.com/free-vector/registration-online-concept_23-2147987277.jpg?size=500&ext=jpg" style="max-width:50%" alt="">
                <h5>Create an Account</h5>
                <p>Create an account with your mobile number.</p>
              </div>
              <div class="col-md-3 pad-0 text-center">
                <img src="https://image.freepik.com/free-vector/image-upload-landing-page_52683-23795.jpg" style="max-width:50%" alt="">
                <h5>Post Free Ad</h5>
                <p>Post a new ad with an attractive title and photo to catch the people's attention.</p>
              </div>
              <div class="col-md-3 pad-0 text-center">
                <img src="https://img.freepik.com/free-vector/image-upload-illustrated-background-landing-page_52683-24623.jpg?size=626&ext=jpg" style="max-width:75%" alt="">
                <h5>Make as featured</h5>
                <p>Featured ad will be our first priority in the listing. Get cashback, If your product sold out with in time.</p>
              </div>
            </div>
          </div>
        </div><br>
        <div class="row margin-0 justify-content-center">
          <div class="col-md-12 col-lg-10 col-xl-10 col-12 pad-0">
            <h4 class="margin-bottom-20 text-center">How people save money?</h4>
            <p>Every person will have some reauirements on small and big goods. In that some of the goods are new and some of it are old. When ever buying some old goods needed a report to wheter to buy or not. We will help you out to find so better goods with brand new feature. We are enabled advanced ad posting mechanisms to help you to find better ad postings.</p>
          </div>
          <div class="col-md-10 pad-top-20">
            <h3 class="text-center">How we help?</h3><br>
            <ul class="list-inline">
              <li class="pad-5"><ion-icon class="text-warning" name="star"></ion-icon>We are using the best ad optimization for showing your ad to right customer and follow up with perfect communicational methods.</li>
              <li class="pad-5"><ion-icon class="text-warning" name="star"></ion-icon>One of the best web pattren image placement.</li>
              <li class="pad-5"><ion-icon class="text-warning" name="star"></ion-icon> We are one of the best free classifieds provider in india.</li>
              <li class="pad-5"><ion-icon class="text-warning" name="star"></ion-icon> Easy ad featuring with low budget is a wonderful opportunity for business users.</li>
              <li class="pad-5"><ion-icon class="text-warning" name="star"></ion-icon> We have 24 X 7 customer support to solve your issues and support customer to add content.</li>
              <li class="pad-5"><ion-icon class="text-warning" name="star"></ion-icon> Unilimited ads posting is the one of the best feature in Ebechana. Most of the people are interested to list their products online.</li>
            </ul><br><br>
            <div class="text-center margin-bottom-40">
              <button class="btn btn-success">Start Posting a free Ad</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      msg: null
    }
  }
}
</script>
<style lang="stylus" scoped>
.about
  padding-top 6vh
  min-height 100vh
  .about-block
    background-color #ffffff
    padding 20px 14px
    // border 1px dashed #ddd
    border-radius 2px
</style>
